<template>
  <div>
    <b-card>

      <div class="form-group">
        <label for="title">Nazwa (To pole wyświetla się na stronie oferty): </label>
        <input
          id="title"
          v-model="user.name"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="title">email: </label>
        <input
          id="title"
          v-model="user.email"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="title"> telefonu: </label>
        <input
          id="title"
          v-model="user.phone_number"
          type="text"
          class="form-control"
        >
      </div>

      <div class="input">
        <b-form-group>
          Opis profilu
        </b-form-group>
        <textarea
          v-model="user.description"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="title">Miasto: </label>
        <input
          id="title"
          v-model="user.city"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="title">Kraj: </label>
        <input
          id="title"
          v-model="user.country"
          type="text"
          class="form-control"
        >
      </div>

      <div class="form-group">
        <label for="title">Chcesz zmienić hasło? Wpisz je tutaj: </label>
        <input
          id="title"
          v-model="user.password"
          type="text"
          class="form-control"
        >
      </div>

      <button
        class="btn btn-primary submit"
        @click="submit()"
      >
        Edytuj profil
      </button>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import { BCard, BCardText } from 'bootstrap-vue'
import swal from 'sweetalert2'

export default {
  name: 'Profile',
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      user: {

      },
    }
  },
  mounted() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}user`, config)
      .then(response => {
        this.user = response.data.success
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    submit() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.put(`${process.env.VUE_APP_API_URL}user`, {
        name: this.user.name,
        email: this.user.email,
        phone_number: this.user.phone_number,
        description: this.user.description,
        city: this.user.city,
        country: this.user.country,
        password: this.user.password,
      }, config)
        .then(response => {
          swal.fire({
            title: 'Sukces!',
            icon: 'success',
            text: 'Zmiany zostały zapisane!',
            type: 'success',
            confirmButtonText: 'OK',
          })
        })
        .catch(error => {
          swal.fire({
            title: 'Błąd!',
            icon: 'error',
            text: 'Nie udało się zapisać zmian. Dane występują już w bazie danych.',
            type: 'error',
            confirmButtonText: 'OK',
          })
        })
    },
  },
}
</script>

<style>
.input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.input input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(204, 204, 204, 0.344);
    border-radius: 5px;
    background: rgba(204, 204, 204, 0.468);
}
.input textarea {
    width: 100%;
    height: 100px;
}
.submit {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(204, 204, 204, 0.344);
    border-radius: 5px;
    background: rgba(204, 204, 204, 0.468);
    margin-top: 10px;
}
::placeholder {
    color: rgb(255, 255, 255);
}
</style>
