<template>
  <div>
    <form @submit.prevent="AddTimeReservation">
      <div>
        data
        <b-form-datepicker
          id="date"
          v-model="orderDate"
          class="w-100"
          required
        />
      </div>

      <div>
        Godzina
        <b-form-timepicker
          v-model="Time"
          class="w-100"
          required
        />
      </div>

      <div>
        Czas (w godzinach)
        <b-form-input
          v-model="hours"
          type="number"
          class="w-100"
          required
        />
      </div>

      <button class="btn btn-success mt-2">
        dodaj rezerwację czasu
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import {
  BCard, BForm, BFormInput, BFormSelect, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormInput,
    BForm,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
  },
  data() {
    return {
      order: {},
      employees: [],
      selectedEmployeeId: null,
      orderDate: '',
      Time: null,
      hours: null,
    }
  },
  methods: {
    AddTimeReservation() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const postData = {
        date: this.orderDate,
        start_time: this.Time,
        hours: this.hours,
      }

      axios.post(`${process.env.VUE_APP_API_URL}Time-Reservation`, postData, config)
        .then(response => {
          Swal.fire('sukces', 'Pomyślnie dodano zamówienie', 'success')
        })
    },
  },
}
</script>
