<template>
  <div>
    <b-card class="mt-5 card ">
      <h1>
        Widok pracownika
      </h1>
    </b-card>

    <b-card class="card">
      <h2>
        Grafik
      </h2>
      <div class="summary">
        <div class="col-xl-12 col-sm-12">
          <div>
            <EmployeeViewCalendar />
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="card">
      <h2>
        Klienci
      </h2>
      <div>
        <EmployeeClients />
      </div>
    </b-card>

    <b-card class="card">
      <h2>
        Dodaj rezerwacje czasu
      </h2>
      <employee-time-reservation />
    </b-card>

    <b-card class="card">
      <h2>
        Edycja profilu
      </h2>
      <div>
        <EmployeeEditProfile />
      </div>
    </b-card>

    <b-card class="card">
      <h2>
        Sprzęty
      </h2>
      <div>
        <EmployeeEquipment />
      </div>
    </b-card>

    <b-card class="card">
      <button
        class="btn btn-danger"
        @click="logout()"
      >
        Wyloguj
      </button>
    </b-card>

    <div style="padding-bottom: 40px" />
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import { BCalendar, BCalendarDay, BCard } from 'bootstrap-vue'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import EmployeeSummary from './EmployeeSummary.vue'
import EmployeeClients from './EmployeeClients.vue'
import EmployeeEditProfile from './EmployeeEditProfile.vue'
import EmployeeViewCalendar from './EmployeeViewCalendar.vue'
import EmployeeEquipment from './EmployeeEquipment.vue'
import EmployeeTimeReservation from './EmployeeTimeReservation.vue'

export default {
  name: 'Employeeview',
  components: {
    BCalendar,
    BCalendarDay,
    BCard,
    FullCalendar, // make the <FullCalendar> tag available
    EmployeeSummary,
    EmployeeClients,
    EmployeeEditProfile,
    EmployeeViewCalendar,
    EmployeeEquipment,
    EmployeeTimeReservation,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridWeek',
        events: [],
      },
    }
  },
  mounted() {
    document.title = 'Widok pracownika - Pickmode'
    this.FetchSummary()
  },
  methods: {
    logout() {
      swal.fire({
        title: 'Czy na pewno chcesz się wylogować?',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak, wyloguj mnie!',
        icon: 'warning',
      }).then(result => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
        axios.get(`${process.env.VUE_APP_API_URL}Logout`, config)
          .then(response => {
            localStorage.removeItem('accessToken')
            this.$router.push('/login')
          })
          .catch(error => {
            swal.fire({
              title: 'Error',
              text: error.response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok',
            })
          })

        localStorage.removeItem('accessToken')
        this.$route.push('/login')
      })
    },
    FetchSummary() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Report/Employee`, config)
        .then(response => {
          this.sumary = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 1200px) {

}

.card {
    margin-right: 50px;
    margin-left: 50px;
}

@media screen and (max-width: 700px) {
    .card {
        margin-right: 5px;
        margin-left: 5px;
    }
    .fc {
        height: 500px;
    }
}
</style>
