<template>
  <div>
    <hr>
    <div
      v-for="equipment in equipment"
      :key="equipment"
      class="mt-1"
    >
      <div class="d-flex justify-content-between">
        <div>
          nazwa: {{ equipment.name }} <br> Aktualna ilość: {{ equipment.quantity }}
        </div>

        <div>
          <button
            class="btn btn-danger"
            style="padding: 10px;"
            @click="ShowDeleteItemsModal (equipment.id)"
          >
            Usuń przedmioty
          </button>
        </div>
      </div>
      <hr>
    </div>

    <div
      v-if="!equipment[0]"
      class="text-center"
    >
      <h2>
        Brak sprzętu do wyświetlenia
      </h2>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { BModal, BPopover } from 'bootstrap-vue'

export default {
  name: 'EmployeeEquipment',
  components: {
    BModal,
    BPopover,
  },
  data() {
    return {
      equipment: [],
    }
  },
  mounted() {
    this.FetchEquipment()
  },
  methods: {
    FetchEquipment() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.get(`${process.env.VUE_APP_API_URL}Employee/Equipment`, config)
        .then(response => {
          this.equipment = response.data.equipment
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            type: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    ShowDeleteItemsModal(id) {
      Swal.fire({
        title: 'Ilość przedmiotów usuwanych z magazynu',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Usuń',
        showLoaderOnConfirm: true,
        preConfirm: login => {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }

          const putData = {
            quantity: login,
            equipment_id: id,
          }

          axios.put(`${process.env.VUE_APP_API_URL}Employee/Equipment/${id}`, putData, config)
            .then(response => {
              Swal.fire({
                title: 'Sukces',
                text: 'Przedmioty zostały usunięte z magazynu',
                type: 'success',
                icon: 'success',
                confirmButtonText: 'Ok',
              })
              this.FetchEquipment()
              return response.data
            })
        },
      })
    },
  },
}

</script>
