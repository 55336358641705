<template>
  <div>

    <div
      v-for="customer in customers"
      :key="customer"
    >
      <div class="customer">
        <div>
          {{ customer.name }} | {{ customer.email }}
        </div>

        <router-link :to="{name: 'EmployeeClientView', params: { id: customer.id }}">
          <div class="btn btn-primary">
            Podgląd
          </div>
        </router-link>

      </div>
      <hr>
    </div>

    <div
      v-if="!customers[0]"
      class="text-center"
    >
      <h2>
        Brak klientów do wyświetlenia
      </h2>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      customers: [],
    }
  },
  mounted() {
    this.fetchEmployeeClients()
  },
  methods: {
    fetchEmployeeClients() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Employee/Customers`, config)
        .then(response => {
          this.customers = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

  },
}
</script>

<style>
    .customer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }
</style>
